import Papa from "papaparse";

/**
 * Parse the CSV File and take a sample to display the preview
 */
export const parseCSVFile = (file: File, sampleSize = 100): Promise<Array<string[]>> =>
  new Promise((resolve, reject) => {
    return Papa.parse<string[]>(file, {
      preview: sampleSize,
      complete: (result) => {
        resolve(result.data);
      },
      error: (parseError: Error) => {
        reject(parseError.message);
      },
    });
  });

/**
 * Download from URL option
 * Parse the CSV and take a sample to display the preview
 */
export const downloadAndParseCSVFile = (file: string, sampleSize = 100): Promise<Array<string[]>> =>
  new Promise((resolve, reject) => {
    return Papa.parse<string[]>(file, {
      preview: sampleSize,
      download: true,
      complete: (result) => {
        resolve(result.data);
      },
      error: (parseError: Error) => {
        reject(parseError.message);
      },
    });
  });

import { Theme } from "@radix-ui/themes";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { IconContext } from "react-icons";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { App } from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ApiKeys } from "./screens/ApiKeys";
import { MapView } from "./screens/Map";
import { Projects } from "./screens/Projects";
import { Usage } from "./screens/Usage";
import { UserModal } from "./screens/UserModal";

import "@radix-ui/themes/styles.css";
import { Home } from "./screens/Home/Home";

if (import.meta.env.VITE_NODE_ENV !== "dev") {
  Sentry.init({
    dsn: "https://ba71223b49e280102425a744fcab8cb0@o4507255025106944.ingest.de.sentry.io/4507255027335248",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/bunkasearch\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_NODE_ENV,
  });
}

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "/usage",
          element: <Usage />,
        },
        {
          path: "/api-keys",
          element: <ApiKeys />,
        },
        {
          path: "/user",
          element: <UserModal />,
        },
        {
          path: "/projects",
          element: <Projects isPublic={false} />,
        },
        {
          path: "/map/:datasetID",
          element: <MapView />,
        },
      ],
    },
  ],
  {
    future: {
      // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
      v7_normalizeFormMethod: true,
    },
  },
);

// biome-ignore lint/style/noNonNullAssertion: document.getElementById("app") must exist
ReactDOM.createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <IconContext.Provider value={{ color: "#0F6FE3", className: "icon-instance-node" }}>
      <Theme appearance="light" accentColor="indigo" radius="large" panelBackground="solid">
        <RouterProvider router={router} />
      </Theme>
    </IconContext.Provider>
  </React.StrictMode>,
);

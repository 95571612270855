import { Spinner } from "@radix-ui/themes";
import { useContext } from "react";
import { TopicsContext } from "../../components/TopicsContext";
import type { TopicsProviderType } from "../../components/TopicsContext/types";
import "./style.css";

export const SnackbarImporting = (): JSX.Element => {
  const { isLoading } = useContext<TopicsProviderType>(TopicsContext);
  return (
    <>
      {isLoading ? (
        <div className="snackbar-importing">
          <div className="import-pending-container">
            <div className="import-pending-text">Import en cours</div>
            <Spinner loading={isLoading} className="import-spinner" />
          </div>
        </div>
      ) : null}
    </>
  );
};

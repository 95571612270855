import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import "./style.css";

interface Props {
  showIcon: boolean;
  type: "warning" | "critical" | "info" | "success" | "error";
  text: string;
}

export const Helper = ({ showIcon = true, type, text }: Props): JSX.Element => {
  return (
    <div className={`helper ${type} helper-instance`}>
      {showIcon && (
        <div className="icon-wrapper">
          <RiErrorWarningLine className="icon-error-warning" color={type === "critical" ? "white" : "black"} />
        </div>
      )}

      <div className="orci-augue">
        <span className="text-wrapper">{text}</span>
      </div>
    </div>
  );
};

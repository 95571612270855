import React from "react";
import "./style.css";
import { Progress } from "@radix-ui/themes";
import { RiFileWarningLine } from "react-icons/ri";

export const Usage = (): JSX.Element => {
  return (
    <div className="usage">
      <div className="main">
        <div className="top-bar">
          <div className="text-wrapper-11">Usage</div>
        </div>
        <div className="helper">
          <div className="icon-wrapper">
            <RiFileWarningLine />
          </div>
          <p className="orci-augue">
            Token explication. Aliquam adipiscing tincidunt eu imperdiet elementum odio praesent convallis. Ut netus congue netus morbi neque sapien cras
            egestas convallis. At ac suspendisse et quis lacus condimentum orci montes egestas. Vitae nisl risus pellentesque in sollicitudin fermentum risus
            et. Sociis mi volutpat posuere orci amet eget dui.
          </p>
        </div>
        <div className="list">
          <div className="frame-4">
            <p className="text-wrapper-12">14 564 / 1 000 000 tokens</p>
            <div className="label-2">Updated 12 minutes ago</div>
          </div>
          <Progress value={(140_564 / 1_000_000) * 100} max={100} variant="soft" style={{ width: "100%" }} />
          <button type="button" className="button-2">
            <div className="dashboard-5">Upgrade to pro</div>
          </button>
        </div>
      </div>
    </div>
  );
};

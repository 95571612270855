export const normalisePercentage = (value: number) => Math.ceil((value * 100) / 100);

export const ModelEnum = [
  "distiluse-base-multilingual-cased-v2",
  "all-MiniLM-L6-v2",
  "WhereIsAI/UAE-Large-V1",
  "Alibaba-NLP/gte-base-en-v1.5",
  "OrdalieTech/Solon-embeddings-large-0.1",
  "intfloat/multilingual-e5-large",
];

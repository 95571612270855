import { Button as RaButton } from "@radix-ui/themes";
import "./style.css";

interface Props {
  label?: boolean;
  disabled?: boolean;
  loading?: boolean;
  variant?: "classic" | "solid" | "soft" | "surface" | "outline";
  size?: "1" | "2" | "3";
  className?: string;
  divClassName?: string;
  text?: string;
  onClick?: () => void;
  iconElt?: JSX.Element;
  color?:
    | "gray"
    | "gold"
    | "bronze"
    | "brown"
    | "yellow"
    | "amber"
    | "orange"
    | "tomato"
    | "red"
    | "ruby"
    | "crimson"
    | "pink"
    | "plum"
    | "purple"
    | "violet"
    | "iris"
    | "indigo"
    | "blue"
    | "cyan"
    | "teal"
    | "jade"
    | "green"
    | "grass"
    | "lime"
    | "mint"
    | "sky";
  radius?: "large" | "none" | "full";
}

export const Button = ({
  label = true,
  variant = "solid",
  size = "2",
  loading = false,
  text,
  disabled = false,
  onClick,
  color = "indigo",
  radius = "large",
  iconElt,
}: Props) => {
  return (
    <RaButton radius={radius} color={color} size={size} loading={loading} variant={variant} disabled={disabled} onClick={onClick}>
      {iconElt && iconElt}
      {label && text}
    </RaButton>
  );
};

import { useState } from "react";
import { RiFile3Line, RiFileCopyFill, RiLink, RiTerminalFill } from "react-icons/ri";
import { Button } from "../Button";
import { ImportModal } from "../ImportModal";
import "./style.css";
import { Heading, SegmentedControl } from "@radix-ui/themes";
import type { TaskDataResponse } from "../TopicsContext/types";

interface Props {
  mode: "URL" | "file" | "terminal";
  className: string;
  onFinishImport: (data: TaskDataResponse) => void;
  initialImportFile?: File;
  isPublic: boolean;
}

export const ImportModalModes = ({ isPublic, mode, className, onFinishImport, initialImportFile }: Props) => {
  const [currentMode, setCurrentMode] = useState(mode);
  return (
    <div className={`import-modal-modes ${className}`}>
      <SegmentedControl.Root defaultValue="file" className="tabs" size="3" radius="large">
        <SegmentedControl.Item onClick={() => setCurrentMode("file")} value="file" className="tab">
          File <RiFile3Line color={`${currentMode === "file" ? "rgba(255, 255, 255, 1)" : "#606060"}`} />
        </SegmentedControl.Item>
        <SegmentedControl.Item onClick={() => setCurrentMode("URL")} value="URL" className="tab">
          URL <RiLink color={`${currentMode === "URL" ? "rgba(255, 255, 255, 1)" : "#606060"}`} />
        </SegmentedControl.Item>
        <SegmentedControl.Item onClick={() => setCurrentMode("terminal")} value="terminal" className="tab">
          Terminal <RiTerminalFill color={`${currentMode === "terminal" ? "rgba(255, 255, 255, 1)" : "#606060"}`} />
        </SegmentedControl.Item>
      </SegmentedControl.Root>
      <div className="content">
        {currentMode === "URL" && (
          <>
            <Heading as="h4">Datasource</Heading>
            <ImportModal isPublic={isPublic} doUploadFile={false} onFinishImport={onFinishImport} />
          </>
        )}
        {currentMode === "terminal" && (
          <>
            <div className="code-preview">
              <div className="text-wrapper-4">BunkaToken=78dd-4b56-1561-c49a-11ac-3447-a5ce</div>
              <RiFileCopyFill className="icon-file-copy" />
            </div>
            <div className="code-preview">
              <p className="bunkatoken">
                curl -d @YourDataSet.json <br />\ model=DefaultModel
                <br />\ lang=fr-FRactions
                <br />\ column=MainColumn
                <br />
                \-X POST https://app.bunka.ai/ -H “Authorization: Bearer $BunkaToken”
              </p>
              <RiFileCopyFill className="icon-file-copy" />
            </div>
            <Button onClick={() => {}} text="Full API documentation" variant="outline" />
            <div className="actions">
              <Button onClick={() => {}} text="Done" />
            </div>
          </>
        )}
        {currentMode === "file" && (
          <>
            <Heading as="h4">Datasource</Heading>
            <ImportModal isPublic={isPublic} doUploadFile={true} onFinishImport={onFinishImport} initialImportFile={initialImportFile} />
          </>
        )}
      </div>
      <div className="helper">
        <span className="span">Allowed file types : </span>
        <span className="text-wrapper-3">csv, json, tsv, txt</span>
      </div>
    </div>
  );
};

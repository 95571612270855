import * as Dialog from "@radix-ui/react-dialog";
import { RiCloseLargeFill } from "react-icons/ri";
import { Button } from "../Button";
import { Helper } from "../Helper";
import { ImportModalModes } from "../ImportModalModes";
import "./style.css";
import { Theme } from "@radix-ui/themes";
import type { TaskDataResponse } from "../TopicsContext/types";

interface Props {
  onOpenChange: (v: boolean) => void;
  usageExceed: boolean;
  sizeExceed: boolean;
  isOpened: boolean;
  onFinishImport: (data: TaskDataResponse) => void;
  initialImportFile?: File;
  isPublic: boolean;
}

export const ImportModalWrapper = ({
  onFinishImport,
  onOpenChange,
  usageExceed = false,
  sizeExceed = false,
  isOpened = false,
  initialImportFile,
  isPublic,
}: Props): JSX.Element => {
  const handleImportFinish = (data: TaskDataResponse) => {
    onFinishImport(data);
  };

  return (
    <div className="import-modal-wrapper">
      <Dialog.Root open={isOpened} defaultOpen={false}>
        <Dialog.Portal>
          <Theme>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent">
              <Dialog.Title className={"header"}>
                <div className="text-wrapper-5">New {isPublic ? "public " : ""}dataset</div>
                <Button
                  size="1"
                  onClick={() => {
                    onOpenChange(false);
                  }}
                  label={false}
                  iconElt={<RiCloseLargeFill color="var(--collection-1-colors-neutral-black)" />}
                  variant="outline"
                />
              </Dialog.Title>
              <Dialog.Description className="DialogDescription">
                {usageExceed && <Helper text="Dataset can not exceed 10 000 rows" type="error" showIcon={true} />}
                {sizeExceed && <Helper text="Api token usage is exceeded" type="error" showIcon={true} />}
                {isPublic && <Helper text="A public dataset is shared to all users" type="warning" showIcon={true} />}
              </Dialog.Description>
              <ImportModalModes
                isPublic={isPublic}
                onFinishImport={handleImportFinish}
                className="import-modal-modes-instance"
                mode="file"
                initialImportFile={initialImportFile}
              />
            </Dialog.Content>
          </Theme>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useCallback, useMemo, useState } from "react";
import { supabase } from "../../supabase";
import { getEvtSourceUrl, type postApiTaskType, postImportDataset } from "./apiClient";
import type { ApiTaskName, TaskDataResponse, TopicsProviderType, UploadParams } from "./types";

const { VITE_SUPABASE_DATASET_BUCKET_NAME } = import.meta.env;

const getTaskSourceStoragePath = (user_id: string, dataset_id: number, filename: string) => `${user_id}/${dataset_id}/source_${filename}`;

/*
 * TopicProvider is a React Provider context for the bunka_api and bunka_worker backend
 */
export function TopicsProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>("");
  // TODO merge taskProgress into a dict of taskIDs
  const [taskProgress, setTaskProgress] = useState(0);
  // Supabase current user session
  const session = useSession();

  const monitorTaskProgress = async (onFinishImport: (data: TaskDataResponse) => void, taskResponse: postApiTaskType, taskName = "topics") => {
    const { task_id, dataset_id } = taskResponse;
    // const { signal } = new AbortController();

    fetchEventSource(getEvtSourceUrl(taskName as ApiTaskName, dataset_id, task_id), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session?.access_token}`,
      },
      // signal,
      onmessage: (event) => {
        try {
          // Task result data
          const data: TaskDataResponse = JSON.parse(event.data);
          if (task_id?.length) {
            data.taskID = task_id;
            data.datasetID = dataset_id;
          }
          console.log("Task progress data stream", data);
          const progress = !Number.isNaN(Math.ceil(data.progress)) ? Math.ceil(data.progress) : 0;
          console.log(`Task ${task_id} progress: ${progress}%`);
          setTaskProgress(progress); // Update progress
          if (data.state === "SUCCESS") {
            setIsLoading(false);
            if (onFinishImport) onFinishImport(data);
            return;
          }
          if (data.state === "FAILURE") {
            setErrorText(`Topic processing failed : ${data.error}`);
            setIsLoading(false);
          }
        } catch (error) {
          console.error("EventSource response exception");
          console.error(error);
          setErrorText(`Topic processing failed : ${error}`);
          setIsLoading(false);
        }
      },
      onerror: (err) => {
        console.log(err);
        setErrorText(`Topic processing failed : ${err}`);
        setIsLoading(false);
      },
    });
  };

  // Handle File Upload and POST Request
  const uploadFile = useCallback(
    async (file: File, params: UploadParams) => {
      setIsLoading(true);
      setErrorText("");
      if (!session?.user.id) {
        setIsLoading(false);
        setErrorText("Unauthenticated");
        return;
      }
      const { nClusters, selectedColumn, onFinishImport, taskName, nameLength, language, minCountTerms, model, isPublic, name, removeOutliers, cleanTopics } =
        params;
      const { data: dbData, error: dbError } = await supabase
        .from("datasets")
        .insert({
          user_id: session?.user.id,
          is_public: isPublic,
        })
        .select();

      if (dbError) {
        setErrorText(`Topic processing failed : ${dbError}`);
        setIsLoading(false);
        return;
      }
      const dataset_id = dbData[0].id;
      const { data, error } = await supabase.storage
        .from(VITE_SUPABASE_DATASET_BUCKET_NAME)
        .upload(getTaskSourceStoragePath(session?.user.id, dataset_id, file.name), file, {
          upsert: true,
        });
      if (error) {
        setErrorText(`Topic processing failed : ${error}`);
        setIsLoading(false);
        return;
      }
      try {
        // Generate SHA-256 hash of the file
        const formData = new FormData();
        formData.append("dataset_id", dataset_id.toString());
        formData.append("storage_filepath", data.path);
        formData.append("selected_column", selectedColumn);
        if (nClusters !== "auto") formData.append("n_clusters", nClusters);
        formData.append("name_length", nameLength);
        formData.append("language", language);
        formData.append("remove_outliers", `${removeOutliers ? 1 : 0}`);
        formData.append("clean_topics", `${cleanTopics ? 1 : 0}`);
        formData.append("min_count_terms", minCountTerms);
        formData.append("name", name);
        formData.append("description", "default description");
        formData.append("model", `${model}`);
        // Perform the POST request
        const response = await postImportDataset(taskName, formData, session);
        await monitorTaskProgress(onFinishImport, response, "topics"); // Start monitoring task progress
      } catch (errorExc) {
        // Handle error
        setErrorText(`Topic processing failed : ${errorExc}`);
        setIsLoading(false);
      }
    },
    [monitorTaskProgress, session],
  );

  /**
   * Shared functions and variables of this TopicsContext and TopicsProvider
   */
  const providerValue = useMemo(
    () => ({
      uploadFile,
      isLoading,
      errorText,
      taskProgress,
    }),
    [uploadFile, isLoading, errorText, taskProgress],
  );

  return <TopicsContext.Provider value={providerValue}>{children}</TopicsContext.Provider>;
}

export const TopicsContext = createContext<TopicsProviderType>({} as TopicsProviderType);

import { Box, Flex, Heading, ScrollArea, Tabs } from "@radix-ui/themes";
import { useReducer } from "react";
import { RiSidebarFoldLine } from "react-icons/ri";
import { DocumentListItem, DocumentMetaPanel } from "../DocumentListItem/DocumentListItem";
import type { Document, Topic } from "../TopicsContext/types";
import "./style.css";

export enum MapStyles {
  createScatterMap = "createScatterMap",
}

interface Props {
  selectedTopic?: Topic;
  selectedDocument?: Document;
  topics?: Topic[];
  documents?: Document[];
  handleSelectDocument: (doc_id: string) => void;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

interface StateSidebar {
  value: "list" | "meta";
  doc?: Document;
}

export function MapSidebar({ selectedTopic, selectedDocument, topics, documents, handleSelectDocument, collapsed, setCollapsed }: Props): JSX.Element {
  const [stateOpenDoc, dispatchOpenDoc] = useReducer(reducerOpenDoc, {
    value: "list",
    doc: undefined,
  } as StateSidebar);

  function reducerOpenDoc(_: StateSidebar, doc: Document | null): StateSidebar {
    if (doc === null) {
      return {
        value: "list",
        doc: undefined,
      };
    }
    return {
      value: "meta",
      doc,
    };
  }

  const topicsSizeFraction = (topicSize: number, topics?: Topic[]) => {
    if (!topics) return 0;
    const totalSize = topics.reduce((sum, topic) => {
      if (!topic?.size) return sum;
      return sum + topic?.size;
    }, 0);
    return Math.round((topicSize / totalSize) * 100);
  };

  return (
    <div className={`text-container collapsed-${collapsed}`}>
      <Box className="text-container-collapse-box" onClick={() => setCollapsed(!collapsed)}>
        <RiSidebarFoldLine color="#0F6FE3" className="text-container-collapse" />
      </Box>
      <Box py="2" position="sticky" top="0" right="0" minHeight="100%" minWidth="100%">
        <Flex gap="3" direction="column" justify="start" maxHeight="100dvh">
          {
            selectedTopic ? (
              <>
                <Flex className="text-container-header">
                  <Flex className="text-container-header-left">
                    <Heading className="text-container-title">{selectedTopic.name}</Heading>
                    <Heading className="text-container-subtitle">{topicsSizeFraction(selectedTopic.size, topics)}&nbsp;% of the territory</Heading>
                  </Flex>
                </Flex>
                <ScrollArea type="auto" scrollbars="vertical" className="text-container-doclist">
                  <Tabs.Root defaultValue="list" value={stateOpenDoc.value}>
                    <Tabs.Content value="list" style={{ paddingRight: "12px" }}>
                      {documents
                        ?.filter((doc) => doc.topic_id === selectedTopic.topic_id)
                        .slice(0, 100)
                        .map((doc, index) => (
                          <DocumentListItem
                            key={doc.doc_id}
                            selectedDocument={doc}
                            index={index + 1}
                            handleSelectDocument={handleSelectDocument}
                            dispatchOpenDoc={dispatchOpenDoc}
                          />
                        ))}
                    </Tabs.Content>
                    <Tabs.Content value="meta">
                      <DocumentMetaPanel doc={stateOpenDoc?.doc} dispatchOpenDoc={dispatchOpenDoc} />
                    </Tabs.Content>
                  </Tabs.Root>
                </ScrollArea>
              </>
            ) : selectedDocument ? (
              <>
                <Flex className="text-container-header">
                  <Flex className="text-container-header-left">
                    <Heading className="text-container-title">Document selection</Heading>
                    <Heading className="text-container-subtitle">Topic {topics?.find((topic) => topic.topic_id === selectedDocument.topic_id)?.name}</Heading>
                  </Flex>
                </Flex>
                <Flex className="text-container-doclist">
                  <Tabs.Root defaultValue="list" value={stateOpenDoc.value}>
                    <Tabs.Content value="list">
                      <DocumentListItem
                        key={selectedDocument.doc_id}
                        selectedDocument={selectedDocument}
                        index={1}
                        handleSelectDocument={handleSelectDocument}
                        dispatchOpenDoc={dispatchOpenDoc}
                      />
                    </Tabs.Content>
                    <Tabs.Content value="meta">
                      <DocumentMetaPanel doc={stateOpenDoc?.doc} dispatchOpenDoc={dispatchOpenDoc} />
                    </Tabs.Content>
                  </Tabs.Root>
                </Flex>
              </>
            ) : (
              <>
                <Flex className="text-container-header">
                  <Flex className="text-container-header-left">
                    <Heading className="text-container-title">Select an element</Heading>
                  </Flex>
                </Flex>
                <Flex className="text-container-doclist" />
              </>
            ) // Display a default view or null if no document is selected
          }
        </Flex>
      </Box>
    </div>
  );
}

import React from "react";
import "./style.css";
import { RiAddLargeLine, RiDeleteBinLine, RiFileCopyLine } from "react-icons/ri";

export const ApiKeys = (): JSX.Element => {
  return (
    <div className="API-keys">
      <div className="main-2">
        <div className="top-bar-2">
          <div className="text-wrapper-13">API Keys</div>
        </div>
        <div className="list-2">
          <div className="div-9">
            <div className="t-head">
              <div className="label-3">NAME</div>
              <div className="label-3">KEY</div>
              <div className="label-3">CREATED</div>
              <div className="label-3">LAST USED</div>
              <div className="label-4">{""}</div>
            </div>
            <div className="div-9">
              <div className="API-key-row">
                <div className="label-5">Prod</div>
                <div className="value">
                  <div className="label-6">bk-1234***654</div>
                  <RiFileCopyLine />
                </div>
                <div className="label-5">March 17, 2024</div>
                <div className="label-5">March 17, 2024</div>
                <button type="button" className="button-3">
                  <RiDeleteBinLine />
                  <div className="dashboard-9">Delete</div>
                </button>
              </div>
              <div className="API-key-row">
                <div className="label-5">Prod</div>
                <div className="value">
                  <div className="label-6">bk-1234***654</div>
                  <RiFileCopyLine />
                </div>
                <div className="label-5">March 17, 2024</div>
                <div className="label-5">March 17, 2024</div>
                <button type="button" className="button-3">
                  <RiDeleteBinLine />
                  <div className="dashboard-9">Delete</div>
                </button>
              </div>
              <div className="API-key-row">
                <div className="label-5">Prod</div>
                <div className="value">
                  <div className="label-6">bk-1234***654</div>
                  <RiFileCopyLine />
                </div>
                <div className="label-5">March 17, 2024</div>
                <div className="label-5">March 17, 2024</div>
                <button type="button" className="button-3">
                  <RiDeleteBinLine />
                  <div className="dashboard-9">Delete</div>
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="button-4">
            <RiAddLargeLine color="white" />
            <div className="dashboard-10">Create new API key</div>
          </button>
        </div>
      </div>
    </div>
  );
};

import { type ChangeEvent, useEffect, useRef, useState } from "react";

import { Avatar, Button, Flex, VisuallyHidden } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import { RiUploadLine } from "react-icons/ri";
import { supabase } from "../../supabase";

interface Props {
  url: string;
  size?: "7" | "1";
  onUpload?: (e: ChangeEvent<HTMLInputElement>, p: string) => void;
  fallback: string;
  doUpload: boolean;
}

function checkFileSize(fileInput: File, maxSize: number): boolean {
  if (fileInput) {
    return fileInput.size <= maxSize;
  }
  return false;
}

const getAvatarStoragePath = (user_id: string, filename: string) => `${user_id}/${filename}`;

const { VITE_SUPABASE_AVATAR_BUCKET_NAME } = import.meta.env;
const MAX_SIZE = 1024 * 1024; // 1MB

export default function AvatarUpload({ url, size, onUpload, fallback, doUpload }: Props) {
  const [avatarUrl, setAvatarUrl] = useState<string>(url);
  const [error, setError] = useState<string>();
  const [uploading, setUploading] = useState<boolean>(false);
  // Supabase current user session
  const session = useSession();
  if (!session) {
    return;
  }

  useEffect(() => {
    if (url) downloadImage(url);
  }, [url]);

  const height = size === "7" ? 96 : 24;

  async function downloadImage(path: string) {
    try {
      const { data, error } = await supabase.storage.from(VITE_SUPABASE_AVATAR_BUCKET_NAME).download(path, {
        transform: {
          resize: "cover",
          height,
          width: height,
        },
      });
      if (error) {
        throw error;
      }
      const url = URL.createObjectURL(data);
      setAvatarUrl(url);
    } catch (error) {
      setError("Error downloading image, please retry later");
    }
  }

  async function uploadAvatar(event: ChangeEvent<HTMLInputElement>) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];
      if (!checkFileSize(file, MAX_SIZE)) {
        setError("File size exceeds the limit of 1MB.");
        return;
      }
      const fileExt = file.name.split(".").pop();
      const fileName = `avatar.${fileExt}`;
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const filePath = getAvatarStoragePath(session!.user.id, fileName);
      const { data, error: uploadError } = await supabase.storage.from(VITE_SUPABASE_AVATAR_BUCKET_NAME).upload(filePath, file, {
        upsert: true,
      });

      if (uploadError) {
        throw uploadError;
      }

      onUpload?.(event, data.path);
      downloadImage(data.path);
    } catch (uplError) {
      console.error("Error uploading image", uplError);
      setError("Error downloading image, please retry later");
    } finally {
      setError(undefined);
      setUploading(false);
    }
  }

  const inputRef = useRef(null);

  const handleButtonClick = () => {
    if (!inputRef || !inputRef.current) {
      return;
    }
    (inputRef.current as HTMLInputElement).click();
  };

  return (
    <Flex gap="2" direction="column" align="center">
      <Avatar size={size || "7"} radius="full" src={avatarUrl} fallback={fallback} />
      {doUpload && (
        <>
          <Button size="2" onClick={handleButtonClick} disabled={uploading} variant="soft">
            {!error ? (!uploading ? "Change avatar" : "Uploading ...") : `${error}`}
            <RiUploadLine color="blue" />
          </Button>
          <VisuallyHidden>
            <input ref={inputRef} type="file" accept="image/*" onChange={uploadAvatar} disabled={uploading} />
          </VisuallyHidden>
        </>
      )}
    </Flex>
  );
}

import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SessionWrapper } from "./components/SessionWrapper";
import { supabase } from "./supabase";

import "./index.css";

export const App = () => {
  // biome-ignore lint/style/useConst: <explanation>
  let location = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // Send Analytics
    // ga('send', 'pageview');
  }, [location]);
  return (
    <SessionContextProvider supabaseClient={supabase}>
      <SessionWrapper />
    </SessionContextProvider>
  );
};

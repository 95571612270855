import * as d3 from "d3";
import * as d3Contour from "d3-contour";
import type { Document, Topic } from "../TopicsContext/types";

interface Scales {
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export const margin = {
  top: 20,
  right: 20,
  bottom: 50,
  left: 50,
};
export const plotWidth = window.innerWidth * 0.66;
export const plotHeight = window.innerHeight - 25;
export const DEFAULT_SIZE = 8;
export const DEFAULT_BLUE = "rgba(94, 163, 252, 0.4)";
export const LIGHT_BLUE = "rgba(94, 163, 252, 0.2)";

/**
 * Main SVG Initialization function
 * @param svgRef
 * @param width
 * @param height
 * @param margin
 * @returns
 */
export function createSVG(
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  width: number,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
) {
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  d3.select(svgRef.current!).selectAll("*").remove();
  const svg = d3
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    .select<SVGSVGElement, unknown>(svgRef.current!)
    .attr("viewBox", [0, 0, width, height])
    .attr("width", width)
    .attr("height", height);

  const g = svg.append("g").classed("canvas", true).attr("transform", `translate(${margin.left}, ${margin.top})`);

  return { svg, g };
}

/**
 * Configure Zooming and auto-scaling sizes
 * @param svg
 * @param g
 */
export function configureZoom(svg: d3.Selection<SVGSVGElement, unknown, null, undefined>, g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  const zoom = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.1, 10])
    .on("zoom", ({ transform }) => {
      g.attr("transform", transform);
      g.attr("stroke-width", 1 / transform.k);
      // Mettre à jour la taille de la police des étiquettes en fonction de l'échelle du zoom
      g.selectAll("text[class='topic-label']").style("font-size", `${22 / transform.k}px`);
      g.selectAll("text[class='node-label']").style("font-size", `${22 / transform.k}px`);
      g.selectAll("circle")
        .filter(".document-centroid")
        .attr("r", `${DEFAULT_SIZE / transform.k}`);
    });

  svg.call(zoom);
  return zoom;
}

/**
 * DEPRECATED
 *
 * Bunkatopic's Document.size is not used anymore
 */
export function normalizeSizes(items: Document[]): number[] {
  const sizes = items.map((item) => item.size ?? DEFAULT_SIZE).filter((size) => size !== undefined) as number[];
  const minSize = Math.min(...sizes);
  const maxSize = Math.max(...sizes);
  if (maxSize === minSize) return items.map((_item) => DEFAULT_SIZE);

  return sizes.map((size) => {
    if (size === undefined) return 0; // Cas pour les valeurs undefined
    return (size - minSize) / (maxSize - minSize);
  });
}

/**
 *
 * Génère une couleur bleue avec une transparence basée sur une valeur normalisée
 */
export function getBlueColor(normalizedValue: number): string {
  if (normalizedValue === DEFAULT_SIZE) return DEFAULT_BLUE;
  const alpha = Math.round(normalizedValue * 255);
  return `rgba(94, 163, 252, ${alpha / 255})`;
}

/**
 * Initialize D3 scales from bunkatopics data
 * @param documents
 * @param plotWidth
 * @param plotHeight
 * @returns
 */
export function createScales(documents: { x: number; y: number }[], plotWidth: number, plotHeight: number): Scales {
  const xMin = d3.min(documents, (d) => d.x) || 0;
  const xMax = d3.max(documents, (d) => d.x) || 0;
  const yMin = d3.min(documents, (d) => d.y) || 0;
  const yMax = d3.max(documents, (d) => d.y) || 0;
  const xScale = d3.scaleLinear().domain([xMin, xMax]).range([0, plotWidth]);
  const yScale = d3.scaleLinear().domain([yMin, yMax]).range([plotHeight, 0]);
  return { xScale, yScale, xMin, xMax, yMin, yMax };
}

/**
 * Toggle selected highlight
 * @param elt
 * @param currentlyClickedPolygon
 * @param clickedRgba
 * @param styleProperty
 * @returns
 */
export function highlightSelectedElement(
  elt: SVGElement,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  currentlyClickedPolygon: d3.Selection<any, unknown, null, undefined> | null,
  clickedRgba = "rgba(200, 200, 200, 0.4)",
  styleProperty = "fill",
) {
  // Reset the fill color of the previously clicked polygon to transparent light grey
  if (currentlyClickedPolygon) {
    currentlyClickedPolygon.style(styleProperty, currentlyClickedPolygon.attr("data-color"));
  }

  // Set the fill color of the clicked polygon to transparent light grey and add a red border
  const clickedPolygon = d3.select(elt);
  clickedPolygon.style(styleProperty, clickedRgba);
  return clickedPolygon;
}

/**
 * Create contours
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @returns
 */
export function createConvexHullContours(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  const convexHullData = topics.filter((d) => d.convex_hull);
  const paths = [];
  for (const d of convexHullData) {
    const hull = d.convex_hull;
    const hullPoints: Array<[number, number]> = hull.x_coordinates.map((x, i) => [xScale(x), yScale(hull.y_coordinates[i])]);

    paths.push(
      g
        .append("path")
        .datum(d3.polygonHull(hullPoints))
        .attr("class", "convex-hull-contour")
        .attr("id", d.topic_id)
        .attr("d", (d1) => `M${d1?.join("L")}Z`)
        .style("fill", "none")
        .style("stroke", "transparent")
        .style("data-color", "transparent")
        .style("stroke-width", 2),
    );
  }
  return paths;
}

/**
 * DEPRECATED ConvexHull surfaces
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @returns
 */
export function createConvexHullPolygons(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  const centroids = topics.filter((d) => d.x_centroid && d.y_centroid);
  // Add polygons for topics. Delete if no clicking on polygons
  const topicsPolygons = g
    .selectAll("polygon.topic-polygon")
    .data(centroids)
    .enter()
    .append("polygon")
    .attr("id", (d) => d.topic_id)
    .attr("class", "topic-polygon")
    .attr("points", (d) => {
      const hull = d.convex_hull;
      const hullPoints = hull.x_coordinates.map((x, i) => [xScale(x), yScale(hull.y_coordinates[i])]);
      return hullPoints.map((point) => point.join(",")).join(" ");
    })
    .attr("data-color", "transparent")
    .style("fill", "transparent");

  return topicsPolygons;
}

/**
 * Topographic map background
 * @param documents
 * @param g
 * @param xScale
 * @param yScale
 */
export function createContourLines(
  documents: Document[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  // Add contours
  const contourData = d3Contour
    .contourDensity()
    .x((d: [x: number, y: number]) => xScale(d[0]))
    .y((d: [x: number, y: number]) => yScale(d[1]))
    .size([plotWidth, plotHeight])
    .bandwidth(15)(documents.map((d) => [d.x, d.y]));

  // Define a custom color for the contour lines
  const contourLineColor = "rgb(94, 163, 252, 0.5)";

  // Append the contour path to the SVG with a custom color
  g.selectAll("path.contour")
    .data(contourData)
    .enter()
    .append("path")
    .attr("class", "contour")
    .attr("d", d3.geoPath())
    .style("fill", "none")
    .style("stroke", contourLineColor) // Set the contour line color to the custom color
    .style("stroke-width", 1);
}

/**
 * Remove topographic map background
 * @param g SVGElement
 */
export function destroyContourLines(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  // Append the contour path to the SVG with a custom color
  g.selectAll("path[class='contour']").remove();
}

/**
 * Documents Labels
 * @param document
 * @param g
 * @param xScale
 * @param yScale
 */
export function createLabel(
  document: Document,
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
) {
  const svgElement = svg.node() as SVGElement;
  const transform = d3.zoomTransform(svgElement);

  g.append("text")
    .raise()
    .attr("class", "node-label")
    .attr("x", xScale(document.x))
    .attr("y", yScale(document.y) - 3)
    .style("fill", "rgb(62, 99, 221)")
    .style("text-anchor", "middle") // Center-align the text
    .style("cursor", "pointer")
    .style("font-size", `${30 / transform.k}px`)
    .text(`${document.content.slice(0, 50)}${document.content.length > 50 ? "..." : ""}`);
}

export function destroyLabel(g: d3.Selection<SVGGElement, unknown, null, undefined>) {
  g.selectAll("[class='node-label']").remove();
}

/**
 * DEPRECATED
 * @param topics
 * @param g
 * @param xScale
 * @param yScale
 * @param setSelectedTopic
 * @param setMapSidebarCollapsed
 * @returns
 */
export function createClusterPoints(
  topics: Topic[],
  g: d3.Selection<SVGGElement, unknown, null, undefined>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<Topic | undefined>>,
  setMapSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean | undefined>>,
) {
  const centroids = topics.filter((d) => d.x_centroid && d.y_centroid);
  return g
    .selectAll("circle.topic-centroid")
    .data(centroids)
    .enter()
    .append("circle")
    .raise()
    .attr("id", (d) => d.topic_id)
    .attr("class", "topic-centroid")
    .attr("cx", (d) => xScale(d.x_centroid))
    .attr("cy", (d) => yScale(d.y_centroid))
    .attr("r", 8)
    .style("fill", "red")
    .style("stroke", "black")
    .style("stroke-width", 2)
    .style("cursor", "pointer")
    .on("click", (_event, d) => {
      // Show the content and topic name of the clicked topic centroid in the text container
      setSelectedTopic(d);
      setMapSidebarCollapsed(false);
    });
}

import { Badge } from "@radix-ui/themes";
import mapPreview from "/static/map-preview.png";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import "./style.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  dataset: Partial<Tables<"datasets">>;
  to: string;
}

export const DatasetCard = ({ dataset, to }: Props): JSX.Element => {
  const [username, setUsername] = useState<string>();

  useEffect(() => {
    let ignore = false;
    async function getProfile() {
      if (!dataset?.user_id) {
        return;
      }
      const { data, error } = await supabase.from("profiles").select("username").eq("user_id", dataset?.user_id).single();

      if (!ignore) {
        if (error) {
          console.error(error);
        } else if (data) {
          setUsername(data.username || "");
        }
      }
    }

    getProfile();
    return () => {
      ignore = true;
    };
  }, [dataset?.user_id]);

  return (
    <div className="dataset-card">
      <Link to={to} className="card-item-link">
        <img className="map-preview" alt="Map" src={mapPreview} />
        <div className="labels">
          <div className="top">
            <div className="label">{dataset.name}</div>
            {dataset?.is_public && <Badge color="blue">public</Badge>}
          </div>
          <div className="top">
            <div className="sublabel">{dataset.created_at ? new Date(dataset.created_at).toLocaleDateString() : ""}</div>
            {username && (
              <Badge size="1" color="blue" variant="solid">
                {username}
              </Badge>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

import { useSession } from "@supabase/auth-helpers-react";
import { type FormEvent, useEffect, useState } from "react";
import { supabase } from "../../supabase";
import "./style.css";
import { Callout, Flex } from "@radix-ui/themes";
import { RiErrorWarningLine, RiInformation2Line } from "react-icons/ri";
import Avatar from "../../components/Avatar/Avatar";

export function UserModal() {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState<string>();
  const [website, setWebsite] = useState<string>();
  const [avatar_url, setAvatarUrl] = useState<string>();
  const [error, setError] = useState<string>();

  const session = useSession();

  useEffect(() => {
    let ignore = false;
    async function getProfile() {
      setLoading(true);

      if (!session) {
        return;
      }
      const { user } = session;

      const { data, error } = await supabase.from("profiles").select("username, website, avatar_url").eq("user_id", user.id).single();

      if (!ignore) {
        if (error) {
          console.error(error);
        } else if (data) {
          setUsername(data.username || "");
          setWebsite(data.website || "");
          setAvatarUrl(data.avatar_url || "");
        }
      }

      setLoading(false);
    }

    getProfile();

    return () => {
      ignore = true;
    };
  }, [session]);

  async function updateProfile(event?: FormEvent<HTMLFormElement>) {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    if (!session) {
      return;
    }
    const { user } = session;

    const updates = {
      user_id: user.id,
      username,
      website,
      avatar_url,
    };

    const { error } = await supabase.from("profiles").upsert(updates);
    if (error) {
      setError("Error updating profile, please retry later");
      console.error(error);
    } else {
      setError(undefined);
    }
    setLoading(false);
  }

  return (
    <form onSubmit={updateProfile} className="user-modal">
      <div className="overlay-wrapper">
        <div className="overlay">
          <div className="header-modal">
            <header className="header">
              <div className="modal-title">Edit</div>
            </header>
            <Flex gap="3" direction="column" width="100%">
              <div className="div-10">
                <div className="text-wrapper-14">Profile</div>
                {!username && (
                  <Callout.Root size="1" color="red">
                    <Callout.Icon>
                      <RiErrorWarningLine />
                    </Callout.Icon>
                    <Callout.Text>You need to provide a user name.</Callout.Text>
                    <Callout.Text>Thank you</Callout.Text>
                  </Callout.Root>
                )}
                <div className="input-4">
                  <div className="left-2">
                    <div className="label-7">User name</div>
                    <div className="div-11">
                      <div className="value-2">
                        <input
                          className="user-input"
                          id="username"
                          type="text"
                          required={true}
                          value={username || ""}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="right-actions" />
                </div>
                <div className="input-4">
                  <div className="left-2">
                    <div className="label-7">Email address</div>
                    <div className="div-11">
                      <div className="value-2">
                        <input className="user-input" id="email" type="text" value={session?.user.email} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="right-actions" />
                </div>
                <div className="input-4">
                  <div className="left-2">
                    <div className="label-7">Website URL</div>
                    <div className="div-11">
                      <div className="value-2">
                        <input className="user-input" id="website" type="url" value={website || ""} onChange={(e) => setWebsite(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="right-actions" />
                </div>
                <div className="avatar">
                  <Avatar
                    doUpload={true}
                    // biome-ignore lint/style/noNonNullAssertion: <explanation>
                    url={avatar_url!}
                    size="7"
                    onUpload={(_, url) => {
                      setAvatarUrl(url);
                      updateProfile();
                    }}
                    fallback={username ? username[0]?.toUpperCase() : "A"}
                  />
                </div>
              </div>
              {error && (
                <>
                  <Callout.Root color="red">
                    <Callout.Icon>
                      <RiInformation2Line />
                    </Callout.Icon>
                    <Callout.Text>{error}</Callout.Text>
                  </Callout.Root>
                </>
              )}
              {/* <img className="separator" alt="Separator" src="/static/img/separator.svg" /> */}
              <div className="div-10">
                <div className="provider">
                  <div className="left-4">
                    <button className="button-6" type="submit" disabled={loading}>
                      <div className="dashboard-12">{loading ? "Loading ..." : "Update"}</div>
                    </button>
                    {/* <img className="clip-path-group" alt="Clip path group" src="/static/img/clip-path-group.png" />
                    <div className="meta">
                      <div className="value-3">Google Sign In</div>
                      <div className="div-11">
                        <div className="value-4">Enabled</div>
                        <img className="icon-check-fill-2" alt="Icon check fill" src="/static/img/icon-check-fill-1.svg" />
                      </div>
                    </div> */}
                  </div>
                  <div className="destructive-button">
                    <button className="button-6" type="button" onClick={() => supabase.auth.signOut()}>
                      <div className="dashboard-12">Disconnect</div>
                    </button>
                  </div>
                </div>
              </div>
            </Flex>
          </div>
        </div>
      </div>
    </form>
  );
}

import { IconButton, TextField } from "@radix-ui/themes";
import "./style.css";
import type { LegacyRef } from "react";
import { forwardRef } from "react";

interface Props {
  showLabel: boolean;
  defaultValue?: string;
  text?: string;
  required?: boolean;
  leftIcon?: JSX.Element;
  rightAction?: JSX.Element;
  disabled?: boolean;
}

export const Input = forwardRef(
  (
    { disabled = false, required = false, defaultValue, showLabel = true, text = "Label", leftIcon, rightAction }: Props,
    ref: LegacyRef<HTMLInputElement>,
  ): JSX.Element => {
    return (
      <TextField.Root
        ref={ref}
        required={required}
        className="input-text"
        variant="soft"
        size="3"
        value={defaultValue}
        placeholder={showLabel ? text : undefined}
        disabled={disabled}
      >
        <TextField.Slot>{leftIcon && leftIcon}</TextField.Slot>
        <TextField.Slot>
          {rightAction && (
            <IconButton size="1" variant="ghost">
              {rightAction}
            </IconButton>
          )}
        </TextField.Slot>
      </TextField.Root>
    );
  },
);

import { SessionContextProvider, useSessionContext } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { TopicsProvider } from "../../components/TopicsContext/TopicsContext";
import { supabase } from "../../supabase";
import { AuthSignIn } from "../AuthSignIn";
import { Projects } from "../Projects";
import "./style.css";
import { useLocation } from "react-router-dom";

export const Home = (): JSX.Element => {
  // biome-ignore lint/style/useConst: <explanation>
  let location = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // Send Analytics
    // ga('send', 'pageview');
  }, [location]);

  const { session } = useSessionContext();
  const [passwordResetScreen, showPasswordResetScreen] = useState<boolean>(false);

  supabase.auth.onAuthStateChange(async (event, session) => {
    if (event === "PASSWORD_RECOVERY") {
      // show screen to update user's password
      showPasswordResetScreen(true);
    }
    if (event === "USER_UPDATED") {
      console.log("USER_UPDATED", session);
      // show screen to update user's password
      showPasswordResetScreen(false);
    }
  });
  return (
    <SessionContextProvider supabaseClient={supabase}>
      <TopicsProvider>
        <div className="app-screen">
          <div className="sidebar-container">
            <Sidebar className="sidebar-instance" collapsed={false} />
          </div>
          <div className="app-content">
            {!passwordResetScreen && <Projects isPublic={true} />}
            {!session && passwordResetScreen && <AuthSignIn passwordResetScreen={passwordResetScreen} redirectTo="/projects" />}
          </div>
        </div>
      </TopicsProvider>
    </SessionContextProvider>
  );
};

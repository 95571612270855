import * as d3 from "d3";
import {
  configureZoom,
  createContourLines,
  createConvexHullContours,
  createLabel,
  createSVG,
  createScales,
  destroyLabel,
  getBlueColor,
  highlightSelectedElement,
  margin,
  normalizeSizes,
  plotHeight,
  plotWidth,
} from ".";
import type { BunkaTopicsResponse, Document, Topic } from "../TopicsContext/types";

const rgbaSelectedColor = "rgba(255, 0, 0, 1)";

const DOCUMENTS_LIMIT = 10_000;

export const createScatterPlot = (
  apiData: BunkaTopicsResponse,
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  setSelectedDocument: React.Dispatch<React.SetStateAction<Document | undefined>>,
  setSelectedTopic: React.Dispatch<React.SetStateAction<Topic | undefined>>,
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { docs: documents, topics } = apiData;
  const docs = documents.slice(0, DOCUMENTS_LIMIT);
  // Génération des couleurs bleues
  const sizes = normalizeSizes(docs);
  const blueColors = sizes.map((size) => getBlueColor(size));

  const { svg, g } = createSVG(svgRef, plotWidth, plotHeight, margin);
  const zoom = configureZoom(svg, g); // Utilisation de l'utilitaire

  const { xScale, yScale } = createScales(docs, plotWidth, plotHeight);
  // TODO add switch "topography"
  // createContourLines(docs, g, xScale, yScale);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let currentlyClickedDocument: d3.Selection<any, unknown, null, undefined> | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let currentlyClickedTopic: d3.Selection<any, unknown, null, undefined> | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  let currentlyClickedCountour: d3.Selection<any, unknown, null, undefined> | null;

  g.selectAll("circle.document-centroid")
    .data(docs)
    .enter()
    .append("circle")
    .raise()
    .attr("id", (d) => d.doc_id)
    .attr("class", "document-centroid")
    .attr("cx", (d) => xScale(d.x))
    .attr("cy", (d) => yScale(d.y))
    .attr("r", (_d, idx) => sizes[idx])
    .attr("data-color", (_d, idx) => blueColors[idx])
    .style("fill", (_d, idx) => blueColors[idx])
    .style("cursor", "pointer")
    .on("mouseover", (event, d) => {
      d3.select(event.target).style("fill", "red");
      createLabel(d, svg, g, xScale, yScale);
    })
    .on("mouseout", (event) => {
      d3.selectAll("circle.document-centroid").style("fill", d3.select(event.target).attr("data-color"));
      destroyLabel(g);
    })
    .on("click", (event, d: Document) => {
      currentlyClickedDocument = highlightSelectedElement(event.target, currentlyClickedDocument, rgbaSelectedColor);
      setSelectedDocument(d);
      setSelectedTopic(undefined);
      setCollapsed(false);
    });

  const centroids = topics.filter((d) => d.x_centroid && d.y_centroid);
  const paths = createConvexHullContours(topics, g, xScale, yScale);
  // Add text labels for topic names
  g.selectAll("text.topic-label")
    .data(centroids)
    .enter()
    .append("text")
    .raise()
    .attr("class", "topic-label")
    .attr("id", (d) => d.topic_id)
    .attr("x", (d) => xScale(d.x_centroid))
    .attr("y", (d) => yScale(d.y_centroid) - 12)
    .text((d) => `${d.name.slice(0, 30)}${d.name.length > 30 ? "..." : ""}`) // Use the 'name' property for topic names
    .style("text-anchor", "middle") // Center-align the text
    .style("cursor", "pointer")
    .on("click", (event, d: Topic) => {
      setSelectedTopic(d);
      setSelectedDocument(undefined);
      currentlyClickedTopic = highlightSelectedElement(event.target, currentlyClickedTopic, rgbaSelectedColor);
      currentlyClickedCountour = highlightSelectedElement(
        paths.find((path) => path.attr("id") === d.topic_id)?.node() as SVGElement,
        currentlyClickedCountour,
        "red",
        "stroke",
      );
      setCollapsed(false);
    });

  return { svg, zoom, g, xScale, yScale };
};

import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { Helper } from "../Helper/Helper";
import "./style.css";

export const ErrorBoundary = ({ errorText }: { errorText?: string }): JSX.Element => {
  const error = useRouteError();

  function getContent(error: unknown) {
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return "This page doesn't exist!";
      }

      if (error.status === 401) {
        return "You aren't authorized to see this";
      }

      if (error.status === 503) {
        return "Looks like our API is down";
      }

      if (error.status === 418) {
        return "🫖";
      }

      // the response json is automatically parsed to
      // `error.data`, you also have access to the status
      return JSON.stringify(error.data);
    }
    // explicit error
    if (errorText) return errorText;

    // rethrow to let the parent error boundary handle it
    // when it's not a special case for this route
    throw error;
  }

  return (
    <div className="box">
      <div className="div">
        <Helper showIcon={true} text={getContent(error)} type="info" />
      </div>
    </div>
  );
};

export function reducer(state: { collapsed: boolean }, action: string) {
  switch (action) {
    case "click":
      return {
        collapsed: state.collapsed === false,
      };
    case "open":
      return {
        collapsed: false,
      };
    case "close":
      return {
        collapsed: true,
      };
  }

  return state;
}

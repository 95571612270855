import { Badge, Button, Callout, Card, DropdownMenu, Flex, IconButton, Progress, Spinner, Switch, Text, Tooltip } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { RiErrorWarningLine, RiQuestionMark } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { MapSidebar, MapStyles } from "../../components/MapSidebar/MapSidebar";
import { createScatterPlot } from "../../components/Maps/scatterPlot";
import { SnackbarImporting } from "../../components/SnackbarImporting/SnackbarImporting";
import { TopicsContext } from "../../components/TopicsContext";
import { postDeleteDataset } from "../../components/TopicsContext/apiClient";
import type { BunkaTopicsResponse, Document, Topic, TopicsProviderType } from "../../components/TopicsContext/types";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import { normalisePercentage } from "../../utils";
import "./style.css";
import { DEFAULT_BLUE, createContourLines, createLabel, destroyContourLines, destroyLabel } from "../../components/Maps";

const datasetQuery = (id: string) => supabase.from("datasets").select("*").eq("id", id);
const profilesQuery = (id: string) => supabase.from("profiles").select("*").eq("user_id", id);

interface CreateMapResultType {
  g: d3.Selection<SVGGElement, unknown, null, undefined>;
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>;
}

export function MapView(): JSX.Element {
  const { datasetID } = useParams();
  // Supabase current user session
  const session = useSession();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [apiData, setApiData] = useState<BunkaTopicsResponse>();
  const [datasetOwner, setDatasetOwner] = useState<Tables<"profiles">>();
  const [selectedTopic, setSelectedTopic] = useState<Topic>();
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  const [error, setError] = useState<string>();
  const [dataset, setDataset] = useState<Tables<"datasets">>();
  const { taskProgress } = useContext<TopicsProviderType>(TopicsContext);
  const [mapLoading, setMapLoading] = useState(true);
  const [mapVars, setMapVars] = useState<CreateMapResultType>();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const scatterPlotContainerRef = useRef<HTMLDivElement | null>(null);

  const createScatterMap = useCallback(
    (apiData: BunkaTopicsResponse) => createScatterPlot(apiData, svgRef, setSelectedDocument, setSelectedTopic, setCollapsed),
    [],
  );

  const [currentMapStyle, _setCurrentMapStyle] = useState<MapStyles>(MapStyles.createScatterMap);
  const createMapOptions: { [key: string]: (apiData: BunkaTopicsResponse) => CreateMapResultType } = {
    createScatterMap,
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (apiData === undefined) {
      setMapLoading(true);
    } else {
      // Call the function to create the SVG map
      setMapVars(createMapOptions[currentMapStyle](apiData));
      // After the data is loaded, set the default topic
      switch (currentMapStyle) {
        case MapStyles.createScatterMap:
          if (apiData.topics && apiData.topics.length > 0) {
            // Set the default topic to the first topic in the list
            setSelectedTopic(apiData.topics[0]);
          }
          break;
        default:
          setSelectedDocument(apiData.docs[0]);
      }

      setMapLoading(false);
    }
    return () => {
      setSelectedTopic(undefined);
      setSelectedDocument(undefined);
      setMapLoading(true);
    };
  }, [apiData, currentMapStyle]);

  /**
   * Handle selected document changes
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (mapVars) {
      const { svg, g, xScale, yScale } = mapVars;
      destroyLabel(g);
      // TODO fix blue color from data-color attr
      g.selectAll("circle.document-centroid").style("fill", DEFAULT_BLUE);
      if (!mapLoading && selectedDocument) {
        createLabel(selectedDocument, svg, g, xScale, yScale);
        g.selectAll(`circle.document-centroid[id='${selectedDocument.doc_id}']`).style("fill", "red");
      }
    }
  }, [selectedDocument]);

  const parseData = useCallback(async (blob: Blob) => {
    try {
      const text = await blob.text(); // Convert Blob to string
      const data = JSON.parse(text); // Parse string as JSON
      setApiData(data);
    } catch (exc) {
      setError("Error parsing topics processing result.");
      console.error(exc);
    }
  }, []);

  /**
   * Callback from MapSidebar click on an item
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleSelectDocument = useCallback(
    (doc_id: string) => {
      const document = apiData?.docs.find((doc) => doc_id === doc.doc_id);

      switch (currentMapStyle) {
        case MapStyles.createScatterMap:
          setSelectedDocument(document);
          break;
        default:
          setSelectedDocument(apiData?.docs.find((doc) => doc_id === doc.doc_id));
      }
    },
    [setSelectedDocument, setSelectedTopic, apiData],
  );

  useEffect(() => {
    let loading = true;
    if (!datasetID) {
      return () => {
        loading = false;
      };
    }
    datasetQuery(datasetID).then(({ data, error }) => {
      if (loading && data !== null) {
        const datasetResult = data[0];
        if (!datasetResult) {
          setError("Project does not exist.");
          loading = false;
          return;
        }
        setDataset(datasetResult);
        if (!datasetResult.process_topics_result_filepath || !datasetResult.process_topics_result_bucket) {
          setError("Topics processing result not exist.");
          loading = false;
          return;
        }

        if (datasetResult.is_public) {
          axios
            .get(supabase.storage.from(datasetResult.process_topics_result_bucket).getPublicUrl(datasetResult.process_topics_result_filepath).data.publicUrl)
            .then((res) => {
              setApiData(res.data);
              if (datasetResult.user_id) {
                profilesQuery(datasetResult.user_id).then((data) => {
                  if (data?.data) {
                    setDatasetOwner(data.data[0]);
                  }
                });
              }
            });
        } else {
          supabase.storage
            .from(datasetResult.process_topics_result_bucket)
            .download(datasetResult.process_topics_result_filepath)
            .then(({ data, error }) => {
              if (error) {
                setError("Error downloading topics processing result.");
                loading = false;
                return;
              }

              if (data) {
                parseData(data);
              }
              loading = false;
            });
        }
      }
      if (error) {
        setError("Error fetching projet.");
      }
    });
    return () => {
      setDataset(undefined);
      setApiData(undefined);
      loading = false;
    };
  }, [datasetID, parseData]);

  const mapDescription =
    "This map is created by embedding documents in a two-dimensional space. Two documents are close to each other if they share similar semantic features, such as vocabulary, expressions, and language. The documents are not directly represented on the map; instead, they are grouped into clusters. A cluster is a set of documents that share similarities. A cluster  is automatically described by a few words that best describes it.";

  return (
    <div className="map-view">
      <div className="map-menu">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="solid">
              <Flex gap="2" className="map-menu-label">
                {dataset?.name || "Options"}
                {dataset?.is_public && (
                  <Badge color="blue" variant="surface">
                    public
                  </Badge>
                )}
                {dataset?.user_id === session?.user.id ? <DropdownMenu.TriggerIcon /> : null}
                {datasetOwner?.username && dataset?.user_id !== session?.user.id ? (
                  <Badge color="blue" variant="surface">
                    by {datasetOwner.username}
                  </Badge>
                ) : null}
              </Flex>
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {dataset?.user_id === session?.user.id ? (
              <>
                <DropdownMenu.Item disabled>Edit</DropdownMenu.Item>
                <DropdownMenu.Separator />
                <DropdownMenu.Item disabled>Add to public datasets</DropdownMenu.Item>
                <DropdownMenu.Separator />
                <DropdownMenu.Item
                  color="red"
                  onSelect={async () => {
                    if (dataset) {
                      await postDeleteDataset("topics", dataset.id, session);
                      navigate("/projects");
                    }
                  }}
                >
                  Delete
                </DropdownMenu.Item>
              </>
            ) : null}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>

      {!!error && (
        <Callout.Root color="red" className="map-error">
          <Callout.Icon>
            <RiErrorWarningLine />
          </Callout.Icon>
          <Callout.Text>{error}</Callout.Text>
        </Callout.Root>
      )}
      <div className="scatter-plot-and-text-container">
        <div className="scatter-plot-container" ref={scatterPlotContainerRef}>
          <Tooltip content={mapDescription}>
            <IconButton radius="full" className="scatter-plot-tooltip">
              <RiQuestionMark />
            </IconButton>
          </Tooltip>
          <svg ref={svgRef} className="scatter-plot-svg" />
          {mapLoading && !error && (
            <>
              <Spinner loading={true} className="scatter-plot-loader" />
              {taskProgress > 0 && <Progress className="scatter-plot-progress" value={normalisePercentage(taskProgress)} max={100} variant="soft" />}
            </>
          )}
          <Card className="scatter-plot-switch">
            <Flex gap="3" align="center">
              <Text>Topographic map</Text>
              <Switch
                onCheckedChange={(checked) => {
                  if (checked && apiData?.docs && mapVars) createContourLines(apiData.docs, mapVars.g, mapVars.xScale, mapVars.yScale);
                  else if (mapVars) destroyContourLines(mapVars.g);
                }}
              />
            </Flex>
          </Card>
        </div>
        <MapSidebar
          selectedTopic={selectedTopic}
          selectedDocument={selectedDocument}
          topics={apiData?.topics}
          documents={apiData?.docs}
          handleSelectDocument={handleSelectDocument}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </div>
      <SnackbarImporting />
    </div>
  );
}

import { Spinner } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import type { PostgrestSingleResponse, QueryError } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { DatasetCard } from "../../components/DatasetCard";
import { EmptyCard } from "../../components/EmptyCard";
import { ImportModalWrapper } from "../../components/ImportModalWrapper";
import { SnackbarImporting } from "../../components/SnackbarImporting/SnackbarImporting";
import type { TaskDataResponse } from "../../components/TopicsContext/types";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import "./style.css";

const datasetsQuery = (user_id?: string) => {
  const baseQuery = supabase
    .from("datasets")
    .select(`
    id,
    name,
    description,
    created_at,
    user_id,
    is_public
`)
    .neq("process_topics_result_filepath", null)
    .order("created_at", { ascending: true });
  if (user_id) return baseQuery.eq("user_id", user_id);
  return baseQuery;
};

interface Props {
  isPublic: boolean;
}

export const Projects = ({ isPublic }: Props): JSX.Element => {
  const [datasets, setDatasets] = useState<Partial<Tables<"datasets">>[] | null>(null);
  const [error, setError] = useState<QueryError | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [datasetsLoading, setDatasetsLoading] = useState(false);
  const [importModalIsOpened, setImportModalIsOpened] = useState(false);
  const navigate = useNavigate();
  const [fileData, setFileData] = useState<File>();
  // Supabase current user session
  const session = useSession();

  useEffect(() => {
    let loading = true;
    setDatasetsLoading(loading);
    const callback = ({ data, error }: PostgrestSingleResponse<Partial<Tables<"datasets">>[]>) => {
      if (loading && data !== null) {
        setDatasets(data);
      }
      if (error) {
        setError(error);
      }
    };
    if (!isPublic) {
      datasetsQuery(session?.user.id).then((args) => callback(args));
    } else {
      datasetsQuery()
        .eq("is_public", true)
        .then((args) => callback(args));
    }
    setDatasetsLoading(false);

    return () => {
      loading = false;
      setDatasets(null);
      setDatasetsLoading(loading);
    };
  }, [isPublic, session]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    if (!session) {
      navigate("/projects");
    }
    event.preventDefault();
    setDragActive(false);

    const file = event.dataTransfer.files[0];
    setFileData(file);
    setImportModalIsOpened(true);
  };

  const handleImportFinish = (data: TaskDataResponse) => {
    if (data.state === "SUCCESS") {
      navigate(`/map/${data.datasetID}`);
    } else {
      console.error(data);
    }
  };
  return (
    <div className="projects-dropzone" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
      <div className="home-content">
        <div className="home-main">
          <div className="top-bar">
            <div className="text-wrapper">{isPublic ? "Public datasets" : "Datasets"}</div>
            <Button
              className="button"
              onClick={() => {
                if (!session) {
                  navigate("/projects");
                }
                setImportModalIsOpened(true);
              }}
              text={session ? "New dataset" : "Login"}
            />
          </div>

          <div className="dataset-list">
            <div className="datasets-items">
              <Spinner loading={datasetsLoading && !error} />

              {error && <p>Error : {error ? error.message : "nothing there"}</p>}
              {datasets?.length ? (
                <>
                  {datasets.map((dataset) => (
                    <div key={dataset.id}>
                      <DatasetCard dataset={dataset} to={`/map/${dataset.id}`} />
                    </div>
                  ))}
                  <EmptyCard
                    onClick={() => {
                      if (!session) {
                        navigate("/projects");
                      }
                      setImportModalIsOpened(true);
                    }}
                  />
                </>
              ) : null}
              {datasets && !datasets.length && (
                <EmptyCard
                  onClick={() => {
                    if (!session) {
                      navigate("/projects");
                    }
                    setImportModalIsOpened(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <SnackbarImporting />
      </div>
      <div
        className="dropzone-wrapper"
        style={{
          zIndex: dragActive ? 99999 : -9999,
          visibility: dragActive ? "visible" : "hidden",
        }}
        aria-hidden={!dragActive}
      >
        {dragActive && (
          <div className="dropzone-content">
            <p className="dropzone-text">Drop your files here to start processing</p>
            <img className="dropzone-icon" alt="Icon file upload" src="/static/img/icon-file-upload-line-1.svg" />
          </div>
        )}
      </div>
      <ImportModalWrapper
        onOpenChange={setImportModalIsOpened}
        usageExceed={false}
        sizeExceed={false}
        isOpened={importModalIsOpened}
        onFinishImport={handleImportFinish}
        initialImportFile={fileData}
        isPublic={isPublic}
      />
    </div>
  );
};

import { Card, ScrollArea, Text } from "@radix-ui/themes";
import type { Document } from "../TopicsContext/types";
import "./style.css";

interface Props {
  selectedDocument?: Document;
  size?: "1" | "2" | "3" | "4" | "5";
}

export function DocumentCard({ selectedDocument, size = "1" }: Props): JSX.Element {
  return (
    <Card size={size}>
      <ScrollArea type="always" scrollbars="vertical" style={{ maxHeight: "400px" }}>
        <Text>{selectedDocument?.content}</Text>
      </ScrollArea>
    </Card>
  );
}
